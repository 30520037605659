<template>
  <section class="cadastro">
    <v-container fluid>
      <div class="acao">
        <template v-for="(val, k) in itemMenu">
          <div class="acao-boxs" :key="k">
            <div class="acao-content">
              <div class="acao-item">
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-card 
                      @click="$router.push({name: val.name})"
                      :class="`elevation-${hover ? 6 : 3}`">
                      <v-card-actions class="acao-title">{{ val.realName }}</v-card-actions>
                    </v-card>
                  </template>
                </v-hover>
              </div>
            </div>
          </div>
        </template>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { forEach, filter } from 'lodash'

export default {
  name: 'Cadastro',
  computed: {
    ...mapGetters('users', ['listaItens', 'listaMenuTratado']),
    ...mapGetters('roles', ['permiteAcao', 'moduloName']),
    itemMenu () {
      const resultFilter = filter(this.$store.getters['users/listaMenuTratado'], (item, k) => {
        return k === 'g-fin'
      })

      // return map(resultFilter, item => {

      //   const result = filter(item, subitem => {
      //     return subitem.path === ''
      //   })
      //   console.log(result)
      //   return result
      // })
      let arrayRotas = []

      forEach(resultFilter, item => {
        forEach(item, subitem => {
          forEach(subitem, rota => {
            if (rota.path === '') {
              arrayRotas.push(rota)
            }
          })
        })
      })

      return arrayRotas

    }
  }
}
</script>
<style lang="scss" scoped src="@/assets/styles/view/boxs-menu.scss"></style>